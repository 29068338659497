<template>
  <div
    id="app"
    class="container d-flex flex-column align-items-center justify-content-center min-vh-100"
  >
    <div class="card shadow p-4" style="max-width: 500px; width: 100%">
      <h2 class="text-center mb-4">Iniciar Sesión</h2>

      <!-- Mensaje de error -->
      <p v-if="errorMessage" class="alert alert-danger text-center">{{ errorMessage }}</p>

      <div class="row justify-content-center">
        <!-- Botón de Google Sign-In -->
        <div class="col-6 mb-3">
          <div
            id="g_id_onload"
            data-client_id="257142636186-88d1runssdedtoat3hdvebvoiknd95de.apps.googleusercontent.com"
            data-callback="handleCredentialResponse"
            data-auto_prompt="false"
          ></div>
          <div class="g_id_signin" data-type="standard" style="margin:0 auto"></div>
        </div>
        <div class="col-12">ó</div>
        <!-- Botón de LinkedIn Sign-In -->
        <div class="col-12 mt-3 align-self-center">
          <button @click="loginWithLinkedIn" class="btn btn-linkedin">
            <i class="fab fa-linkedin me-2"></i> LinkedIn
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomePage",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      try {
        // 1. Obtener el token CSRF
        await axios.get("https://devb.virtualent.io/sanctum/csrf-cookie", {
          withCredentials: true,
        });

        // 2. Obtener valor del token CSRF
        const getCookieValue = (name) => {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(";").shift();
        };

        const xsrfToken = getCookieValue("XSRF-TOKEN");

        if (!xsrfToken) {
          throw new Error("CSRF token no encontrado en las cookies.");
        }

        console.log("CSRF Token:", xsrfToken); // Depuración

        // 3. Realizar la solicitud de login con usuario y contraseña
        const response = await axios.post(
          "https://devb.virtualent.io/login",
          {
            email: this.email,
            password: this.password,
          },
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": xsrfToken,
              "X-CSRF-TOKEN": xsrfToken,
            },
          }
        );

        console.log("Login exitoso:", response);

        this.$router.push("/about");
      } catch (error) {
        console.error("Error en el inicio de sesión:", error);
        this.errorMessage = "Error en el inicio de sesión. Verifica tus credenciales.";
      }
    },

    // Método para manejar la respuesta de Google Sign-On
    async handleCredentialResponse(response) {
      try {
        const googleToken = response.credential; // El token de Google

        // Enviar el token de Google al backend para autenticar
        const loginResponse = await axios.post(
          "https://devb.virtualent.io/api/login-google",
          {
            token: googleToken,
          }
        );

        const { access_token } = loginResponse.data;

        // Guardar el token en localStorage y establecer encabezados para futuras solicitudes
        localStorage.setItem("token", access_token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

        // Redirigir al dashboard
        this.$router.push("/about");
      } catch (error) {
        console.error("Error en el inicio de sesión con Google:", error);
        this.errorMessage = "Error en el inicio de sesión con Google.";
      }
    },

    // Método para manejar el inicio de sesión con LinkedIn
    loginWithLinkedIn() {
      // Redirigir a la ruta de autenticación con LinkedIn en el backend
      window.location.href = "https://devb.virtualent.io/auth/linkedin";
    },
  },
  mounted() {
    // Exponer la función handleCredentialResponse en el contexto global
    window.handleCredentialResponse = this.handleCredentialResponse;
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      // Guardar el token en localStorage y establecer encabezados para futuras solicitudes
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Redirigir al dashboard
      this.$router.push("/about");
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 500px;
}

.card {
  border-radius: 8px;
  padding: 2rem;
}

.btn-linkedin {
  background-color: #0077b5;
  color: white;
}

.btn-linkedin:hover {
  background-color: #005582;
}

.alert {
  margin-top: 1rem;
}
.gsi_360329_871751-overlay{
  margin:0 auto;
}
.g_id_signin iframe{
  margin:0 auto;
}
</style>
